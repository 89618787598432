<template>
  <div class="training-kp">
    <div class="title">
      <el-select v-model="select" slot="prepend" clearable  placeholder="请选择" @change="examClick">
      <el-option  v-for="item in options" :key="item.id" :value="item.id" :label="item.title" ></el-option>
    </el-select>
    </div>
    <el-card class="box-card">
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column prop="id" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="real_name" label="考试人员" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" align="center">
        </el-table-column>
        <el-table-column prop="title" label="试卷名称" align="center">
        </el-table-column>
        <el-table-column prop="create_time" label="考试时间" align="center">
        </el-table-column>
        <el-table-column prop="score" label="考试成绩" align="center">
        </el-table-column>
        <el-table-column prop="add" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="testExam(scope.$index, scope.row)">考试详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 分页 -->
    <div class="pagination">
      <pagination @currentChange="handleCurrentChangeExp" @prevClick='prevClick' @nextClick='nextClick' :startPage='startPage' :total='totalExp'></pagination>
    </div>
  </div>
</template>

<script>
import { examHList } from '@/api/exam.js'
export default {
  data () {
    return {
      tableData: [],
      select:'',
      options:[],
      trainer_id:null,
      startPage:1,
      totalExp:null
    }
  },
  created () {
    
  },
  mounted () {
    this.trainer_id = this.$route.query.trainer_id
    this.getList(this.startPage)
  },
  methods: {
    async getList (pages) {
      const obj = {
        page:pages,
        trainer_id:this.trainer_id
      }
      const res = await examHList(obj)
      if(res.data.code == 1) {
        this.options = res.data.result.exam_list
        this.tableData = res.data.result.list
        this.totalExp = res.data.result.count
      }
    },
    async examClick(id) {
      const obj = {
        exam_id:id,
        page:1
      }
      const res = await examHList(obj)
      if(res.data.code == 1) {
        this.tableData = res.data.result.list
      }
    },
    // 考试详情
    testExam( index,row) {
      this.$router.push({
        path:'../topic/testExam',
        query:{
          trainer_id:row.user_id,
          exam_id:row.exam_id
        }
      })
    },
    handleCurrentChangeExp(size) {
      this.getList(size)
    },
    prevClick(size) {
      this.getList(size)
    },
    nextClick(size) {
      this.getList(size)
    },
  }
}
</script>

<style lang="less" scoped>
.training-kp {
  padding: 20px 0;
  .title{
    padding-bottom: 20px;
  }
  .pagination{
    margin-top: 20px;
    text-align: right;
  }
  .delete {
    color: #f6423b;
  }
}
</style>
